import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialogYesNo(props) {
    const { open, onClose, messageBody, onYes } = props


  return (
    <div>
      
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <DialogTitle id="alert-dialog-title">Xəbərdarlıq</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {messageBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={onYes} color="primary">
            Bəli
          </Button>
          <Button onClick={onClose} color="secondary">
            Xeyr
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
