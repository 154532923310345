 //public int u { get; set; } //user id
            //public int ss { get; set; } //session id
            //public int su { get; set; } //session user id
            //public int s { get; set; } //status
            //public string m { get; set; } //message
export async function saveYukselishLog(log) {
    const requestOptions = {
        method: "POST",
    }
    const serverURL = process.env.REACT_APP_API_URL;
    const urlApi = new URL(serverURL + "/api/Yukselish/SaveLog");
    urlApi.search = new URLSearchParams(
        {
            ss: log.ss,
            su: log.su,
            u: log.u,
            s: log.s,
            m: log.m,

        }
    );
    // console.log('SaveLog', JSON.stringify(log))
    return fetch(urlApi.href, requestOptions);
}
export async function saveLog(log) {
    const requestOptions = {
        method: "POST",
    }
    const serverURL = process.env.REACT_APP_API_URL;
    const urlApi = new URL(serverURL + "/api/Exam2022/SaveLog");
    urlApi.search = new URLSearchParams(
        {
            ss: log.ss,
            su: log.su,
            u: log.u,
            s: log.s,
            m: log.m,

        }
    );
    // console.log('SaveLog', JSON.stringify(log))
    return fetch(urlApi.href, requestOptions);
}
export async function saveMultiChoice(answer) {
    const requestOptions = {
        method: "POST",
    }
    //const serverURL ='http://localhost:44353';
    const serverURL= process.env.REACT_APP_API_URL;
    const urlApi = new URL(serverURL + "/api/Exam2022/SaveQ");
    urlApi.search = new URLSearchParams(
        {
            id: answer.id,
            cavab: answer.cavab
        }
    );
    return fetch(urlApi.href, requestOptions);
}

export async function saveMultiChoiceSet(answer) {
    const requestOptions = {
        method: "POST",
    }
    //const serverURL ='http://localhost:44353';
    const serverURL= process.env.REACT_APP_API_URL;
    const urlApi = new URL(serverURL + "/api/Exam2022/SaveQ");
    urlApi.search = new URLSearchParams(
        {
            id: answer.id,
            cavab: answer.cavab
        }
    );
    return fetch(urlApi.href, requestOptions);
}

/*
kod	name
1	Gəlişi gözlənilir
2	İmtahana gəlmədi
3	Qeydiyyatdan keçmə (veb kamera ilə)
4	Qeydiyyatdan keçmə (veb kamerasız)
5	Qeydiyyatdan keçmə
6	Sistemdən çıxış (logout ilə)
7	Sistemdən çıxış (proktor tərəfindən)
8	İmtahana hazırdı
9	İmtahana başladı
10	İmtahan qaydalara uyğun başa çatdırılmışdır
11	İmtahan vaxtın bitməyinə görə başa çatmışdır
12	İmtahan qayda pozuntusuna görə dayandırılmışdır
13	İmtahan texniki səbəblərdən dayandırılmışdır
14	İmtahan fors-major vəziyyətə görə dayandırılmışdır
15	Girişə imtina (səhv şifrə və ya istifadəçi adı)
16	Girişə imtina (face control)
17	1 nəfərdən artıq şəxs
18	Fokusdan bir müddət yayınma
19	İstifadəçinin sistem tərəfindən  blok edilməsi
20	İstifadəçinin proktor tərəfindən  blok edilməsi
21	İstifadəçinin proktor tərəfindən  unblok edilməsi
22	Flash check
23	Proktor tərəfindən şablon mesajın göndərilməsi
24	İstifadəçi tərəfindən  şablon mesajın  göndərilməsi
25	Girişə imtina (qeyri-imtahan günündə)
26	Girişə imtina (e-kabinetdə qeydiyyatı yoxdur)
27	Fokusdan yayınma (realtime)
28	Girişə imtina (imtahan günündə qeyri-imtahan saatında)
29	Girişə imtina (imtahan günündə qeyri-imtahan saatında)
30	Girişə imtina (imtahan nəzərdə tutulmayıb)
31	Proktor tərəfindən xüsusi mesajın  göndərilməsi
32	İstifadəçi tərəfindən xüsusi mesajın  göndərilməsi
33	İstifadəçi imtahanda cavablandırır
34	İmtahan sistemi tərəfindən şablon mesajın göndərilməsi
35	Namizəd tərəfindən təkrar cəhd
*/