import React from "react";

const Admin =()=>{
    return (
        <div>
            Home
        </div>
    )
}
export default Admin;
