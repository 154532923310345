import React, { useEffect, useState } from "react";
import ExamPageHeader from "./header";
// import TestBlock from "./TestBlock";
import NavBlock from "./NavBlock";
import { UserContext } from "../../UserContext";
import { useNavigate } from "react-router-dom";
// import MBIT from "./MBIT";
//import testler from './testler.js';
const Exam = () => {
    // const location = useLocation();
    const navigate = useNavigate();
    const sessionData = {
        user: JSON.parse(sessionStorage.getItem("user")),
        session: JSON.parse(sessionStorage.getItem("session")),
    }
    //const userData = location.state;
    //const [userData, setUserData] = useState(location.state)
    const [userData, setUserData] = useState(sessionData)
    const resetTest = () => {
        ////////////
        const newData = JSON.parse(sessionStorage.getItem("session"));
        newData.lqus.map((x) => {
            x.Cavab = '';
            return true;
        })
        sessionStorage.setItem("session", JSON.stringify(newData));
        ///////////
        userData.session.lqus.map((x) => {
            x.Cavab = '';
            return true;
        })
        setUserData(userData => {
            return { ...userData, ...userData };
        });
    }
    const changeUserCavab = (id, cavab) => {
        //console.log(id, cavab)
        //setUserData(cavab=> userData.session.lqus.find((x) => x.ID == id).Cavab = cavab)
        userData.session.lqus.find((x) => x.ID == id).Cavab = cavab
        //setUserData(userData)
        //console.log(userData)
        setUserData(userData => {
            return { ...userData, ...userData };
        });

    }
    useEffect(
        () => {
            if (userData == null) {
                navigate("/", { replace: true });
            }
            // console.log('Status-9')
        },
    )
    if (userData != null) {
        //console.log(userData)
        return (
            // <UserContext.Provider value={[userData, changeUserCavab]}>
            <UserContext.Provider value={[userData, changeUserCavab, resetTest]}>
                <header>
                    <ExamPageHeader />
                </header>
                <section>
                    {/* <MBIT questions={userData.session.lqus} /> */}
                    <NavBlock />
                </section>
            </UserContext.Provider>
        )
    }
}
export default Exam;

