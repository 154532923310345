import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from './report.module.css';
import HSBar from "react-horizontal-stacked-bar-chart";
import AlertDialog from "../../components/AlertDialog";
import Button from "@mui/material/Button";

const Report = () => {
    // const { id } = useParams("/Report/:id");
    const location = useLocation();
    const [report, setReport] = useState();
    //console.log(location.state)
    const userID = location.state.session.su.id;
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [messageBody, setMessageBody] = useState();

    useEffect(() => {
        const serverURL = process.env.REACT_APP_API_URL;
        const urlApi = new URL(serverURL + "/api/Yukselish/GetExamResult/");
        const urlData = {
            userID: userID,
        };
        urlApi.search = new URLSearchParams(urlData);
        const requestOptions = {
            method: "GET",
        };
        fetch(urlApi.href, requestOptions)
            .then((x) => x.json())
            .then(x => setReport(x))
            .catch((error) => {
                console.log(error);
            });

    }, [userID]);
    const shkala = [
        'Ünsiyyət tərzi',
        'Düşüncə tərzi',
        'Qərarvermə',
        'İş tərzi'
    ]
    const paramName = [
        'İntrovert (İ) – Ekstravert (E)',
        'Fikirlərə əsaslanan (N) – Təcrübəyə əsaslanan (S)',
        'Duyğusal (F) – Məntiqli (T)',
        'Planlaşdıran (J) – Araşdıran (P)'
    ]
    const psixotipler = [
        { shifr: 'ISTP', tip: 'Virtuoz' },
        { shifr: 'ISTJ', tip: 'İdarəçi' },
        { shifr: 'ISFP', tip: 'Macəraçı' },
        { shifr: 'ISFJ', tip: 'Müdafiəçi' },
        { shifr: 'INTP', tip: 'Məntiqçi' },
        { shifr: 'INTJ', tip: 'Memar' },
        { shifr: 'INFP', tip: 'Vasitəçi' },
        { shifr: 'INFJ', tip: 'Aktivist' },
        { shifr: 'ESTP', tip: 'Sahibkar' },
        { shifr: 'ESTJ', tip: 'İcraçı' },
        { shifr: 'ESFP', tip: 'Əyləndirici' },
        { shifr: 'ESFJ', tip: 'Konsul' },
        { shifr: 'ENTP', tip: 'Debatçı' },
        { shifr: 'ENTJ', tip: 'Komandir' },
        { shifr: 'ENFP', tip: 'Təşviqatçı' },
        { shifr: 'ENFJ', tip: 'Öndər' }
    ]
    const Result = (props) => {
        const { bal, netice } = props.report;
        //console.log(props.report)
        return paramName.map((param, i) => {
            return (
                <tr key={i}>
                    <td style={{ width: '50vw', textAlign: 'left' }}>{param}</td>
                    <td style={{ width: '10vw' }}>{bal[i]}</td>
                    {/* <td style={{ width: '10vw' }}>{faiz[i]}</td> */}
                    <td style={{ width: '10vw' }}>{netice[i]}</td>
                </tr>
            )
        })
    }
    const ResultSemimiyyet = (props) => {
        const { bal } = props.report;
        //console.log(props.report)
        if (bal[4] >= 18 && bal[4] <= 63) {
            return (
                <>
                    <h3 style={{ textAlign: 'center', fontSize: '16pt' }}>Sualların bir çoxuna səmimi cavab vermisiniz.</h3>
                    {/* <h3 style={{ textAlign: 'center', fontSize: '16pt' }}>Sosial istəklilik göstəricisi norma çərçivəsindədir</h3>
                    <p style={{ textAlign: 'justify', fontSize: '14pt' }}>
                        Sosial istəklilik – başqalarının gözləntilərinə uyğun cavab vermə cəhdi üzrə göstəriciniz yüksək deyil. Bu isə sualların bir çoxunda səmimi cavablar verməyinizin göstəricisidir.
                    </p> */}
                </>
            )
        }
        if (bal[4] >= 64 && bal[4] <= 108) {
            return (
                <>
                    <h3 style={{ textAlign: 'center', fontSize: '16pt' }}>Sualların bir çoxuna qeyri-səmimi cavablar vermə ehtimalınız yüksəkdir.</h3>
                    {/* <h3 style={{ textAlign: 'center', fontSize: '16pt' }}>Sosial istəklilik göstəricisi yüksəkdir</h3>
                    <p style={{ textAlign: 'justify', fontSize: '14pt', marginLeft: '10vw', marginRight: '10vw' }}>
                        Sosial istəklilik – başqalarının gözləntilərinə uyğun cavab vermə cəhdi üzrə göstəriciniz yüksəkdir. Bu isə sualların bir çoxunda sosial istəkli cavablar vermə ehtimalının göstəricisidir. Nəticə etibarilə psixotip tam şəkildə Sizi təsvir etməyə bilər.
                    </p> */}
                </>
            )
        }
    }
    const ShifrLink = (props) => {
        let link;
        switch (props.shifr) {
            case 'İSTP':
            case 'ISTP':
                link = "../../psixotip/ISTP.pdf";
                break;
            case 'İSTJ':
            case 'ISTJ':
                link = "../../psixotip/ISTJ.pdf";
                break;
            case 'İSFP':
            case 'ISFP':
                link = "../../psixotip/ISFP.pdf";
                break;
            case 'İSFJ':
            case 'ISFJ':
                link = "../../psixotip/ISFJ.pdf";
                break;
            case 'İNTP':
            case 'INTP':
                link = "../../psixotip/INTP.pdf";
                break;
            case 'İNTJ':
            case 'INTJ':
                link = "../../psixotip/INTJ.pdf";
                break;
            case 'İNFP':
            case 'INFP':
                link = "../../psixotip/INFP.pdf";
                break;
            case 'İNFJ':
            case 'INFJ':
                link = "../../psixotip/INFJ.pdf";
                break;
            case 'ESTP':
                link = "../../psixotip/ESTP.pdf";
                break;
            case 'ESTJ':
                link = "../../psixotip/ESTJ.pdf";
                break;
            case 'ESFP':
                link = "../../psixotip/ESFP.pdf";
                break;
            case 'ESFJ':
                link = "../../psixotip/ESFJ.pdf";
                break;
            case 'ENTP':
                link = "../../psixotip/ENTP.pdf";
                break;
            case 'ENTJ':
                link = "../../psixotip/ENTJ.pdf";
                break;
            case 'ENFP':
                link = "../../psixotip/ENFP.pdf";
                break;
            case 'ENFJ':
                link = "../../psixotip/ENFJ.pdf";
                break;
            default:
                break;
        }
        return (
            <div style={{ textAlign: 'center' }}>
                <a href={link} target='_blank' rel="noreferrer">{props.shifr} psixotipi haqqında ətraflı</a>
            </div>
        )
    }
    const ResultInChart = (props) => {

        const { faiz, netice } = props.report;
        const l = ['İntrovert (İ)', 'Fikirlərə əsaslanan (N)', 'Duyğusal (F)', 'Planlaşdıran (J)'];
        const r = ['Ekstravert (E)', 'Təcrübəyə əsaslanan (S)', 'Düşünən (T)', 'Araşdıran (P)'];
        const shifr = netice.join('');
        const psixotip = psixotipler.find(x => x.shifr == shifr).tip;

        return (
            <div>
                <h3 style={{ textAlign: 'center', fontSize: '16pt' }}>{psixotip} ({shifr})</h3>
                <br />
                <ShifrLink shifr={shifr} />
                <br />
                {netice.map((_, i) => (
                    <Fragment key={i}>
                        <h4 style={{ textAlign: 'center', fontSize: '14pt' }}>{shkala[i]}</h4>
                        <table className={styles.resultChart}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '15vw' }}>
                                        <span className={styles.faiz}>{parseFloat(faiz[i * 2]).toFixed(1)}%</span>
                                        <br />
                                        <span className={styles.c}>{l[i]}</span>
                                    </td>
                                    <td style={{ width: '50vw' }}>
                                        <HSBar width='50vw'
                                            data={[
                                                { value: parseFloat(faiz[i * 2]), color: '#56979A' },
                                                { value: parseFloat(faiz[i * 2 + 1]), color: 'rgb(152, 76, 159)' }]} />
                                    </td>
                                    <td style={{ width: '15vw' }}>
                                        <span className={styles.faiz}>{(parseFloat(faiz[i * 2 + 1])).toFixed(1)}%</span>
                                        <br />
                                        <span className={styles.c}>{r[i]}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </Fragment>
                )
                )

                }
            </div>
        )
    }
    const quitExam = async () => {
        // if (!location.state.canEdit) {
        //     window.location.replace("https://yukselish.az/profile/");
        //     return;
        // }
        const serverURL = process.env.REACT_APP_API_URL;
        const urlApi = new URL(serverURL + "/api/Yukselish/ResultFromDim2Yukselish");
        const qeydID = location.state.session.su.userID;
        const { netice } = report;
        const shifr = netice.join('');
        urlApi.search = new URLSearchParams({
            qeydID: qeydID,
            netice: shifr
        });
        // console.log(urlApi.search);
        const requestOptions = {
            method: "POST",
        };
        await fetch(urlApi.href, requestOptions)
            .then((x) => x.json())
            .then((x) => {
                if (x.message == 1) {
                    sessionStorage.clear();
                    window.location.replace("https://yukselish.az/profile/");
                }
                else {
                    setMessageBody('Cavabı yükləmək mümkün olmadı');
                    setDialogIsOpen(true);
                    return;
                }
            }
            )

    }
    const closeDialog = () => {
        setDialogIsOpen(false);
    };
    return (
        <>
            {dialogIsOpen && (
                <AlertDialog
                    open={dialogIsOpen}
                    onClose={closeDialog}
                    messageBody={messageBody}
                />
            )} <div>
                <br />
                <h2 style={{ textAlign: 'center' }}>Namizədin nəticəsi barədə arayış</h2>
                <br />
                {report &&
                    <>
                        <table className={styles.result}>
                            <thead>
                                <tr>
                                    <th>Parametr</th>
                                    <th>Bal</th>
                                    {/* <th>Faiz</th> */}
                                    <th>Nəticə</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Result report={report} />
                            </tbody>
                        </table>
                        <br />
                        <hr />
                        <br />
                        <ResultSemimiyyet report={report} />
                        <br />
                        <hr />
                        <br />
                        <ResultInChart report={report} />
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                color={location.state.canEdit ? "success" : "error"}
                                variant= "contained"
                                onClick={quitExam}>{location.state.canEdit ? "İmtahanı bitir" : "Əsas səhifəyə keçid"}
                            </Button>
                            {/* <button style={{ textAlign: 'center', fontSize: '16pt' }} onClick={quitExam}> İmtahanı bitir </button> */}
                        </div>
                        <br />  <br />
                    </>
                }
            </div>
        </>
    )
}
export default Report;
