import React from "react";
// import Typography from '@mui/material/Typography';
import Intro from "./Intro";
import { useParams } from "react-router-dom";

const Home = () => {
    const { qeydID } = useParams("/:qeydID");
    return (
       qeydID && <div>
            {/* <Typography align="center" gutterBottom variant="h4" component="h4">
                Yüksəliş müsabiqəsi üzrə imtahan
            </Typography> */}

            <div style={{ marginLeft: '5vw', marginTop: '5vh' }}>
                <Intro qeydID={qeydID} />
            </div>
        </div>

       
    )
}
export default Home;
