import React, { useState, useEffect } from "react";
import styles from "./MbtiExam.module.css";
import MbtiRowCell from './MbtiRowCell';

const MbtiRow = (props) => {
    const { question } = props;
    const ID = question.ID;
    const [inactive, setInactive] = useState();
    useEffect(() => {
        setInactive(question.Cavab != "");
    }, [question.Cavab]);
    const handleInactive = () => {
        setInactive(true);
    };
    //   console.log(ID, question)
    //   {
    //     "ID": 139,
    //     "OrderID": 139,
    //     "Cavab": "",
    //     "LeftText": "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    //     "RightText": "Odio hic corporis blanditiis est sit natus perferendis aliquid.",
    //     "Shkala": 1,
    //     "DuzEks": 1
    // }
    const inactiveClass = inactive
        ? styles.question + " " + styles.inactive
        : styles.question;
    // console.log(inactive, inactiveClass);
    // if (question.OrderID == 1) {
    //   console.log(question.Cavab == convertUserResponse("lmax"));
    //   console.log(question.Cavab == convertUserResponse("lmed"));
    //   console.log(question.Cavab == convertUserResponse("lmin"));
    //   console.log(question.Cavab == convertUserResponse("rmin"));
    //   console.log(question.Cavab == convertUserResponse("rmed"));
    //   console.log(question.Cavab == convertUserResponse("rmax"));
    // }
    const convertUserResponse = (p, de) => {
        switch (p) {
            case "lmax":
                return de == 1 || de == 2 ? 1 : 6;
            case "lmed":
                return de == 1 || de == 2 ? 2 : 5;
            case "lmin":
                return de == 1 || de == 2 ? 3 : 4;
            case "rmin":
                return de == 1 || de == 2 ? 4 : 3;
            case "rmed":
                return de == 1 || de == 2 ? 5 : 2;
            case "rmax":
                return de == 1 || de == 2 ? 6 : 1;
            default:
                return;
        }
    };
    return (
        <li className={inactiveClass} data-list={ID}>
            <span data-order={question.OrderID}>{question.OrderID}. </span>
            <div className={styles.mobile}>
                <div className={styles.caption + " " + styles.mobileLeft}>
                    {question.LeftText}
                </div>
                <div className={styles.caption + " " + styles.mobileRight}>
                    {question.RightText}
                </div>
            </div>
            <div className={styles.decision}>
                <div className={styles.caption + " " + styles.leftText}>
                    {question.LeftText}
                </div>
                <div className={styles.options} data-q={ID}>
                    <MbtiRowCell
                        handleInactive={handleInactive}
                        q={ID}
                        o={question.OrderID}
                        p="lmax"
                        de={question.DuzEks}
                        checked={question.Cavab == convertUserResponse("lmax", question.DuzEks)}
                        daire={{
                            daireType: "max",
                            daireToolTip: "tam uyğundur",
                            position: "left",
                        }}
                    />
                    <MbtiRowCell
                        handleInactive={handleInactive}
                        q={ID}
                        p="lmed"
                        de={question.DuzEks}
                        o={question.OrderID}
                        checked={question.Cavab == convertUserResponse("lmed", question.DuzEks)}
                        daire={{
                            daireType: "med",
                            daireToolTip: "uyğundur",
                            position: "left",
                        }}
                    />
                    <MbtiRowCell
                        handleInactive={handleInactive}
                        q={ID}
                        p="lmin"
                        de={question.DuzEks}
                        o={question.OrderID}
                        checked={question.Cavab == convertUserResponse("lmin", question.DuzEks)}
                        daire={{
                            daireType: "min",
                            daireToolTip: "nisbətən uyğundur",
                            position: "left",
                        }}
                    />
                    <MbtiRowCell
                        handleInactive={handleInactive}
                        q={ID}
                        p="rmin"
                        de={question.DuzEks}
                        o={question.OrderID}
                        checked={question.Cavab == convertUserResponse("rmin", question.DuzEks)}
                        daire={{
                            daireType: "min",
                            daireToolTip: "nisbətən uyğundur",
                            position: "rigth",
                        }}
                    />
                    <MbtiRowCell
                        handleInactive={handleInactive}
                        q={ID}
                        p="rmed"
                        de={question.DuzEks}
                        o={question.OrderID}
                        checked={question.Cavab == convertUserResponse("rmed", question.DuzEks)}
                        daire={{
                            daireType: "med",
                            daireToolTip: "uyğundur",
                            position: "rigth",
                        }}
                    />
                    <MbtiRowCell
                        handleInactive={handleInactive}
                        q={ID}
                        p="rmax"
                        de={question.DuzEks}
                        o={question.OrderID}
                        checked={question.Cavab == convertUserResponse("rmax", question.DuzEks)}
                        daire={{
                            daireType: "max",
                            daireToolTip: "tam uyğundur",
                            position: "rigth",
                        }}
                    />
                </div>
                <div className={styles.caption + " " + styles.rightText}>
                    {question.RightText}
                </div>
            </div>
        </li>
    );
};

export default MbtiRow;