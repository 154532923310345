import React, { useState, useEffect, useContext, useRef } from "react";
import styles from "./MbtiExam.module.css";
import { UserContext } from "../../UserContext";
import { saveYukselishLog } from "../../components/util";
import AlertDialog from "../../components/AlertDialog";

const MbtiRowCell = (props) => {
  //const userData = useContext(UserContext);
  const [userData, changeUserCavab] = useContext(UserContext);
  //console.log(userData)
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [messageBody, setMessageBody] = useState();
  const [canEdit, setCanEdit] = useState(true);
  const [checked, setChecked] = useState(props.checked);
  const [changed, setChanged] = useState(false);
  const daireCheck = useRef();
  useEffect(() => {
    if (checked) {
      // console.log( daireCheck.current)
      const listItem = daireCheck.current.parentElement.parentElement.parentElement.parentElement;
      //console.log(listItem)
      handleInactive();
      if (listItem.nextSibling != null) {
        listItem.nextSibling.classList.remove(styles.inactive);
        listItem.nextSibling.scrollIntoView();
      }
      const parent1 = daireCheck.current.parentElement.parentElement;
      if(parent1)resetAnotherCircle(parent1);
      daireCheck.current.classList.add(par.checkIcon);
      daireCheck.current.parentElement.classList.add(par.daireStyle2);
    }
  }, [checked]);
  useEffect(() => {
    if (changed) {
      submitAnswer(par).then(() => {
        const  timer1= setTimeout(function() {
          //your code to be executed after 1 second
          setChecked(true);
        }, 1000);
        return () => {
          clearTimeout(timer1);
        };
        //console.log("changed");
      })

    }
  }, [changed]);
  React.useEffect(() => {
    //console.log(canEdit)
    setCanEdit(() => userData.session.su.status == 10 ? false : true);
  }, [userData.session.su.status]);

  const closeDialog = () => {
    setDialogIsOpen(false);
  };
  const { daire, q, p, handleInactive, o, de } = props;
  // console.log(props)
  let daireStyle1 = "";
  let daireStyle2 = "";
  let toolTipStyle = "";
  switch (daire.daireType) {
    case "max":
      daireStyle1 += styles.max;
      break;
    case "med":
      daireStyle1 += styles.med;
      break;
    case "min":
      daireStyle1 += styles.min;
      break;

    default:
      break;
  }
  switch (daire.position) {
    case "left":
      daireStyle2 = styles.activeY;
      daireStyle1 += " " + styles.left;
      toolTipStyle = styles.tooltiptext + " " + styles.green;
      break;
    case "rigth":
      daireStyle2 = styles.activeP;
      daireStyle1 += " " + styles.right;
      toolTipStyle = styles.tooltiptext + " " + styles.purple;
      break;
    default:
      break;
  }
  const cl =
    styles.option +
    " " +
    styles.tooltip +
    " " +
    styles.condition +
    " " +
    daireStyle1;
  const par = {
    p: p,
    q: q,
    o: o,
    de: de,
    checkIcon: styles.checkIcon,
    daireStyle1: daireStyle1,
    daireStyle2: daireStyle2,
  };
  const convertUserResponse = (p, de) => {
    switch (p) {
      case "lmax":
        return de == 1 || de == 2 ? 1 : 6;
      case "lmed":
        return de == 1 || de == 2 ? 2 : 5;
      case "lmin":
        return de == 1 || de == 2 ? 3 : 4;
      case "rmin":
        return de == 1 || de == 2 ? 4 : 3;
      case "rmed":
        return de == 1 || de == 2 ? 5 : 2;
      case "rmax":
        return de == 1 || de == 2 ? 6 : 1;
      default:
        return;
    }
  };
  const submitAnswer = async (par) => {
    //console.log(par.p, par.q)
    const serverURL = process.env.REACT_APP_API_URL;
    const urlApi = new URL(serverURL + "/api/Yukselish/SaveQ");
    const sessionUserID = userData.session.su.id;
    const response = convertUserResponse(par.p, par.de);
    //console.log('de-check',response);
    urlApi.search = new URLSearchParams({
      //int session_user_id, int question_id, string cavab
      session_user_id: sessionUserID,
      question_id: par.q,
      cavab: response,
    });
    // console.log(urlApi.search);
    const requestOptions = {
      method: "POST",
    };

    await fetch(urlApi.href, requestOptions)
      .then((x) => x.json())
      .then(async (x) => {
        if (x.message == '0') {
          setMessageBody('Cavabı yeniləmək mümkün olmadı');
          setDialogIsOpen(true);
          return;
        }
        //console.log('okoko')
        const newData = JSON.parse(sessionStorage.getItem("session"));
        const cavab = convertUserResponse(par.p, par.de);
        changeUserCavab(par.q, cavab)
        //console.log(par.q,cavab)
        newData.lqus.find((x) => x.ID == par.q).Cavab = cavab;
        sessionStorage.setItem("session", JSON.stringify(newData));

        let log = {
          ss: userData.session.su.sessionID,
          su: userData.session.su.id,
          u: userData.session.su.userID,
          s: 33,
          m: cavab,
        };
        // console.log(log);
        await saveYukselishLog(log);
        // .then((x) => console.log(x));
        //userData.session.lqus.find(x=>x.ID==par.q).Cavab= convertUserResponse(par.p);
        //console.log(userData)

        //console.log('response',x, 'log', log);
        if (x.semimi) {
          const semimi = x.semimi;
          const semimiXebSayi = x.semimiXebSayi;
          if (semimi[0] >= 16 && semimiXebSayi[0] == 0) {
            setMessageBody(
              "Hörmətli iştirakçı, testə davam etdikdə daha diqqətli olmağınız xahiş olunur. Seçdiyiniz cavablar Sizi tam təsvir etməlidir."
            );
            setDialogIsOpen(true);
            log = {
              ss: userData.session.su.sessionID,
              su: userData.session.su.id,
              u: userData.session.su.userID,
              s: 34,
              m: 1 + ';' + x.cehd,
            };
            await saveYukselishLog(log);
            return;
          }
          if (semimi[1] >= 16 && semimiXebSayi[1] == 0) {
            setMessageBody(
              "Hörmətli iştirakçı, testə davam etdikdə daha diqqətli olmağınız xahiş olunur. Seçdiyiniz cavablar Sizi tam təsvir etməlidir."
            );
            setDialogIsOpen(true);
            log = {
              ss: userData.session.su.sessionID,
              su: userData.session.su.id,
              u: userData.session.su.userID,
              s: 34,
              m: 2 + ';' + x.cehd,
            };
            await saveYukselishLog(log);
            return;
          }
          if (semimi[2] >= 16 && semimiXebSayi[2] == 0) {
            setMessageBody(
              "Hörmətli iştirakçı, testə davam etdikdə daha diqqətli olmağınız xahiş olunur. Seçdiyiniz cavablar Sizi tam təsvir etməlidir."
            );
            setDialogIsOpen(true);
            log = {
              ss: userData.session.su.sessionID,
              su: userData.session.su.id,
              u: userData.session.su.userID,
              s: 34,
              m: 3 + ';' + x.cehd,
            };
            await saveYukselishLog(log);
            return;
          }
          //if (semimi[0] >= 16 && semimi[1] >= 16 && semimi[2] >= 16) {
          if (semimiXebSayi[2] != 0 && semimiXebSayi[3] == 0) {
            setMessageBody(
              "Hörmətli iştirakçı, verdiyiniz cavablar tam səmimi olmaya bilər. Testin sonunda alınacaq nəticələrin Sizə aid olması və faydalı diaqnostik məlumat almağınız üçün sualları daha diqqətli cavablandırmağınız və ya testi yenidən keçməyiniz tövsiyyə olunur."
            );
            setDialogIsOpen(true);
            log = {
              ss: userData.session.su.sessionID,
              su: userData.session.su.id,
              u: userData.session.su.userID,
              s: 34,
              m: 4 + ';' + x.cehd,
            };
            await saveYukselishLog(log);
            return;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changeAnswer = (e, par) => {
    e.preventDefault();
    if (!canEdit) {
      setMessageBody('İmtahan yekunlaşdığı üçün cavabları dəyişmək olmaz.');
      setDialogIsOpen(true);
      return;
    }
    //Evvelki bosh olmamalidir. Bu sherti ishlemek
    const check = userData.session.lqus.some((q) => {
      return q.OrderID < par.o && q.Cavab == ''
    });
    if (check) {
      setMessageBody('Suallar ardıcıl cavablandılrımalıdır.');
      setDialogIsOpen(true);
      return;
    }
    //Tekrar eyni duymeni basmaq 
    if (daireCheck.current.classList.contains(par.checkIcon)) {
      console.log('Tekrar eyni duymeni basmaq ')
      return;
    }

    //console.log(e.target,daireCheck.current.classList.contains(par.checkIcon))
    //resetAnotherCircle(par);
    // const elem = e.target;
    // elem.classList.add(par.checkIcon);
    // elem.parentElement.classList.add(par.daireStyle2);
    //const listItem = document.querySelector('[data-list="' + par.q + '"]');

    //listItem.classList.add(styles.inactive);
    //console.log('handleInactive', handleInactive)
    //console.log(JSON.parse(sessionStorage.getItem("session")).lqus.find(x=>x.ID==par.q))
    //console.log(userData.session.lqus.filter(x=>x.ID==par.q))

    setChanged(true);

  };
  const resetAnotherCircle = (parent) => {
    // const allCircle = document.querySelector(
    //   '[data-q="' + par.q + '"]'
    // ).children; //('[data-id="box1"]');
    for (let x of parent.children) {
      x.classList.remove(styles.activeY);
      x.classList.remove(styles.activeP);
      x.children[0].classList.remove(par.checkIcon);
    }
  };
  return (
    <>
      {dialogIsOpen && (
        <AlertDialog
          open={dialogIsOpen}
          onClose={closeDialog}
          messageBody={messageBody}
        />
      )}
      <div
        data-p={p}
        className={cl}
        aria-checked="false"
        aria-labelledby="foo"
        tabIndex="0"
        onClick={(e) => changeAnswer(e, par)}
      >
        <span
          ref={daireCheck}
          //onClick={(e) => changeAnswer(e, par)}
          className="fa-solid fa-check"
        ></span>
        <span className={toolTipStyle}>{daire.daireToolTip}</span>
      </div>
    </>

  );
};
export default MbtiRowCell;