import { Route, Routes } from 'react-router-dom';
import { Home, NotFound, Report,SignIn,Admin,Exam,STT } from './views';

function App() {
  return (
    <Routes>
    <Route exact  path='/' element={<Home />} />
    <Route exact  path='/:qeydID' element={<Home />} />
    <Route exact  path='/Exam' element={<Exam />} />
    <Route exact  path='/STT' element={<STT />} />
    <Route path='/Report' element={<Report />} />
    <Route path='*' element={<NotFound />} />
    <Route path="/Signin" element={<SignIn />} />
    <Route path="/Admin" element={<Admin/>} />
  </Routes>
  );
}

export default App;
