import React from "react";
import styles from "./MbtiExam.module.css";
import MbtiRow from './MbtiRow';

const MbitExam = (props) => {
  const { questions } = props;
  return (
    <div className={styles.container}>
      <div className={styles.questions}>
        <ul>
          {questions.map((question) => (
            <MbtiRow key={question.ID} question={question} />
          ))}
        </ul>
      </div>
    </div>
  );
};
export default MbitExam;
