import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveYukselishLog } from "../../components/util";
import AlertDialog from "../../components/AlertDialog";

export default function Intro(props) {
  const [qeydID, setQeydID] = useState();
  const [session, setSession] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [messageBody, setMessageBody] = useState();

  const navigate = useNavigate();

  useEffect(
    () => {
      setQeydID(props.qeydID);
    }, [props.qeydID]
  )
  useEffect(() => {
    if (qeydID) {
      getUserSessionInfo();
    }
  }, [qeydID]);

  useEffect(() => {
    if (session) {
      const log = {
        ss: session.su.sessionID,
        su: session.su.id,
        u: session.su.userID,
        s: 9,
        m: 1,
      };
      // console.log(log);
      saveYukselishLog(log);
      const userData = {
        user: JSON.parse(sessionStorage.getItem("user")),
        session: JSON.parse(sessionStorage.getItem("session")),
      };
      navigate("/STT/", { state: userData });
    }
  }, [session]);

  async function getUserSessionInfo() {
    const serverURL = process.env.REACT_APP_API_URL;
    //console.log("getUserSessionInfo");
    let urlPart, urlData;
    if (process.env.REACT_APP_MODE === "1") {
      urlPart = "GetYukUserInfo";
      urlData = {
        userID: qeydID,
      };
    }
    const urlApi = new URL(serverURL + "/api/Yukselish/" + urlPart);
    urlApi.search = new URLSearchParams(urlData);
    const requestOptions = {
      method: "GET",
    };
    const response = await fetch(urlApi.href, requestOptions)
      .then((x) => x.json())
      .catch((error) => {
        console.log(error);
      });

    if (!response) {
      setMessageBody("Sistemə qoşulmaq mümkün olmadı");
      setDialogIsOpen(true);
      return;
    } else {
      if (response.Message) {
        setMessageBody(response.Message);
        setDialogIsOpen(true);
        return;
      }
      if (response.message) {
        setMessageBody(response.message);
        setDialogIsOpen(true);
        return;
      }
    }
    sessionStorage.clear();
    sessionStorage.setItem("session", JSON.stringify(response));
    setSession(response);
  }
  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  return (
    <>
      {dialogIsOpen && (
        <AlertDialog
          open={dialogIsOpen}
          onClose={closeDialog}
          messageBody={messageBody}
        />
      )}
      <div>... Yüklənmə gedir ...</div>
    </>
  );
}
