import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";
import { saveYukselishLog } from "../../components/util";

const Signin = () => {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
    const [username, setUsername] = useState(); //
    const [password, setPassword] = useState();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [messageBody, setMessageBody] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            getUserSessionInfo();
        }
    }, [user]);
    useEffect(() => {
        if (session) {
            locate2NextPage();
        }
    }, [session]);
    const locate2NextPage = () => {
        const log = {
            ss: session.su.sessionID,
            su: session.su.id,
            u: session.su.userID,
            s: 4,
            m: 1,
        };
        // console.log(log);
        saveYukselishLog(log)
        //   .then((x) => x.json())
        // .then((x) => console.log(x));
        const userData = {
            user: JSON.parse(sessionStorage.getItem("user")),
            session: JSON.parse(sessionStorage.getItem("session")),
        }
        // const userData = {
        //     user: user,
        //     session: session,
        // }
        navigate("/STT/", { state: userData });
    };
    async function getUserSessionInfo() {
        const serverURL = process.env.REACT_APP_API_URL;

        let urlPart, urlData;
        if (process.env.REACT_APP_MODE === '1') {
            urlPart = "GetExamUserInfo";
            urlData = {
                userID: user.ID,
            };
        }
        const urlApi = new URL(serverURL + "/api/Yukselish/" + urlPart);
        urlApi.search = new URLSearchParams(urlData);
        const requestOptions = {
            method: "GET",
        };
        const response = await fetch(urlApi.href, requestOptions)
            .then((x) => x.json())
            .catch((error) => {
                console.log(error);
            });

        if (!response) {
            setMessageBody("Sistemə qoşulmaq mümkün olmadı");
            setDialogIsOpen(true);
            return;
        } else {
            if (response.Message) {
                setMessageBody(response.Message);
                setDialogIsOpen(true);
                return;
            }
            if (response.message) {
                setMessageBody(response.message);
                setDialogIsOpen(true);
                return;
            }
        }

        sessionStorage.clear();
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("session", JSON.stringify(response));

        //const session = JSON.parse(sessionStorage.getItem("session"));
        setSession(response);
    }



    const closeDialog = () => setDialogIsOpen(false);

    const submitForm = async (e) => {
        e.preventDefault();

        // send the username and password to the server

        if (username === "") {
            setMessageBody("Fərdi identifikasiya nömrəsi (FİN) daxil edilməyib");
            setDialogIsOpen(true);
            return;
        }
        if (!(username.length >= 5 && username.length <= 9)) {
            setMessageBody(
                "Fərdi identifikasiya nömrəsi (FİN) düzgün daxil edilməyib."
            );
            setDialogIsOpen(true);
            return;
        }
        if (password === "") {
            setMessageBody("Şifrə daxil edilməyib");
            setDialogIsOpen(true);
            return;
        }
        const serverURL = process.env.REACT_APP_API_URL;
        const urlApi = new URL(serverURL + "/api/Yukselish/Login");
        urlApi.search = new URLSearchParams({
            userName: username,
            password: password,
        });
        // const urlApi = serverURL + "/api/Proktor/GetProktorInfo/";
        const requestOptions = {
            method: "POST",
        };
        await fetch(urlApi.href, requestOptions)
            .then((x) => x.json())
            .then((x) => {
                if (x.message) {
                    setMessageBody(x.message);
                    setDialogIsOpen(true);
                    return;
                }
                setUser(x);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //     <div style={{ textAlign:"center", marginTop: "5%" }}>
    //     {/* <img src="/images/dim_logo.svg" alt="DIM" style={{ width: "10vw" }} />
    //     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
    //     <img src="/images/yukselis.svg" alt="DIM" style={{ width: "22vw" }} />
    // </div>

    return (
        <div>
            <div className="login-container">
                {dialogIsOpen && (
                    <AlertDialog
                        open={dialogIsOpen}
                        onClose={closeDialog}
                        messageBody={messageBody}
                    />
                )}

                <div className="login-header">İmtahan sisteminə giriş</div>
                <form>
                    <label htmlFor="username">İstifadəçi adı</label>
                    <input autoComplete="false" type="text" value={username} onChange={({ target }) => setUsername(target.value)} />
                    <label htmlFor="password">Şifrə</label>
                    <input autoComplete="false" type="password" value={password} onChange={({ target }) => setPassword(target.value)} />
                    <button className="loginButton" type="button" onClick={submitForm}>Daxil Ol</button>
                </form>
            </div>
        </div>
    )
}
export default Signin;