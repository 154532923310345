import React from "react";
import { UserContext } from "../../UserContext";
import Link from '@mui/material/Link';
// import { useNavigate } from "react-router-dom";

const ExamPageHeader = () => {
  const [userData] = React.useContext(UserContext);
  const user = userData.session.su;
  // const navigate = useNavigate();
  const locate2Home = () => {
    sessionStorage.clear();
    //navigate("https://yukselish.az/",{replace:true});
    window.location.replace("https://yukselish.az/profile/");
  };
  return (
    <>
      <div className="heading">
        <h1>Dövlət İmtahan Mərkəzi - imtahan sistemi</h1>
      </div>
      <div className="user-profile">
        <span>
          {user.soyadi +' ' +user.adi}
          <br />
          <Link
            style={{
              color: 'white',
              fontSize: '1rem'
            }}
            component="button"
            variant="body2"
            onClick={locate2Home}
          >
            Çıxış
          </Link>
        </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <img
          alt="Namized"
          className="user-image"
          src={user.UserImage ? 'data:image/jpeg;base64,' + user.UserImage : "./images/Sample_User_Icon.png"}
        /> */}
      </div>
    </>
  );
};
export default ExamPageHeader;
