import React, { useState } from "react";
import { UserContext } from "../../UserContext";
// import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";
import AlertDialogYesNo from "../../components/AlertDialogYesNo";

const toggleButton = (e) => {
  e.preventDefault();
  e.target.classList.toggle("active");
  const panel = document.getElementsByClassName("sidenav")[0];
  //let ques = document.getElementById("questions");
  if (panel.style.display === "none") {
    panel.style.display = "block";
    //ques.style.marginLeft = "20px"
  } else {
    panel.style.display = "none";
    //ques.style.marginLeft = "auto";
    //ques.style.marginRight = "auto";
  }
};

const Suallar = (props) => {
  const { suallar } = props;
  // const navigate = useNavigate();
  async function goto(event) {
    event.preventDefault();
    const sualN = event.target.dataset.sual;
    const listItem = document.querySelector('[data-order="' + sualN + '"]');
    listItem.scrollIntoView();
    //navigate("/exam/"+sualN, { replace: true });
  }

  return suallar.map((sual) => {
    // --blue-hover:#0263ca;
    // const buttonStyle=sual.Cavab!=''?'backgroundColor:#0263ca':'';
    
    if (sual.Cavab!='') {
      return (
        <li key={sual.ID}>
          <button data-sual={sual.ID} onClick={goto} className="ansblue">
            {sual.OrderID}
          </button>
        </li>
      );
    }
    else {
      return (
        <li key={sual.ID}>
          <button data-sual={sual.ID} onClick={goto} className="answhite">
            {sual.OrderID}
          </button>
        </li>
      );
    }

  });
};

const NavBlock = () => {
  const [userData, , resetTest, changeUserStatus] = React.useContext(UserContext);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [yesNoDialogIsOpen, setYesNoDialogIsOpen] = useState(false);
  const [restartTestDialogIsOpen, setRestartTestDialogIsOpen] = useState(false);
  const [restart, setRestart] = useState(false);
  const [messageBody, setMessageBody] = useState();
  const [yesNoMessageBody, setYesNoMessageBody] = useState();
  const navigate = useNavigate();
  const suallar = userData.session.lqus;

  const restartTest = async () => {
    const serverURL = process.env.REACT_APP_API_URL;
    const urlApi = new URL(serverURL + "/api/Yukselish/RestartTest");
    const sessionUserID = userData.session.su.id;
    urlApi.search = new URLSearchParams({
      session_user_id: sessionUserID,
    });
    const requestOptions = {
      method: "POST",
    };

    fetch(urlApi.href, requestOptions)
      .then((x) => x.json())
      .then((x) => {
        if (x.error) {
          setMessageBody(x.error);
          setDialogIsOpen(true);
          setRestart(false);
          return;
        }
        if (x.message > 0) {
          resetTest();
          setRestart(false);
          window.location.reload();
          return;
        }
        setRestart(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    //console.log(canEdit)
    setCanEdit(() => userData.session.su.status == 10 ? false : true);
  }, [userData.session.su.status]);
  React.useEffect(() => {
    if (restart) {
      restartTest();
    }
  }, [restart]);

  const locate2Result = () => {
    if (!canEdit) navigate("/Report", { state: {session:userData.session, canEdit:canEdit} });
    const boshCavab = userData.session.lqus.some((x) => x.Cavab == "");
    if (boshCavab) {
      setMessageBody(
        "Cavablandırmadığınız suallar vardır. Testi tamamladıqdan sonra nəticələrə baxa bilərsiniz."
      );
      setDialogIsOpen(true);
      //return;
    } else {
      setYesNoMessageBody(
        "Testi yalnız bir dəfə keçə bilərsiniz. Testi tamamlayıb nəticələrinizi görmək istəyirsinizmi?"
      );
      setYesNoDialogIsOpen(true);
    }
  };

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const closeRestartDialog = () => {
    return Promise.resolve(setRestartTestDialogIsOpen(false));
  };
  const closeYesNoDialog = () => {
    setYesNoDialogIsOpen(false);
  };
  //
  const testRestartDialoq = async () => {
    //console.log(par.p, par.q)
    const boshCavab = userData.session.lqus.some((x) => x.Cavab != "");
    if (!boshCavab) {
      setMessageBody("Testləri cavablandıra bilərsiniz");
      setDialogIsOpen(true);
      return;
    }
    setYesNoMessageBody("Testi yenidən başlamaq istəyirsiniz?");
    setRestartTestDialogIsOpen(true);
  };
  const testRestartOnYes = async () => {
    await closeRestartDialog();
    setRestart(true);
  };
  return (
    <div className="questions-panel">
      {dialogIsOpen && (
        <AlertDialog
          open={dialogIsOpen}
          onClose={closeDialog}
          messageBody={messageBody}
        />
      )}
      {yesNoDialogIsOpen && (
        <AlertDialogYesNo
          open={yesNoDialogIsOpen}
          onClose={closeYesNoDialog}
          onYes={() => {
            changeUserStatus(10);
            navigate("/Report", { state:{session:userData.session, canEdit:canEdit} });
          }}
          messageBody={yesNoMessageBody}
        />
      )}
      {restartTestDialogIsOpen && (
        <AlertDialogYesNo
          open={restartTestDialogIsOpen}
          onClose={closeRestartDialog}
          onYes={() => testRestartOnYes()}
          messageBody={yesNoMessageBody}
        />
      )}
      <button className="accordion" onClick={toggleButton}>
        <i className="fa-solid fa-chevron-right"></i>
      </button>
      <div className="sidenav panel ques-menu">
        {/* <p id="countdown" className="countdown"></p> */}
        <ul>
          <Suallar suallar={suallar} />
        </ul>
        <div style={{ textAlign: "center", marginBottom: "5vh" }}>
          <br />
          <Button
            style={{
              width: "90%",
              borderRadius: 10,
              backgroundColor: "#21b6ae",
              padding: "14px 14px",
              fontSize: "14px",
            }}
            variant="contained"
            endIcon={<NavigateNextIcon />}
            onClick={() => locate2Result()}
          >
            Nəticələri göstər
          </Button>
          <br />
          <br />
          {canEdit && <Button
            style={{
              width: "90%",
              borderRadius: 10,
              backgroundColor: "#21b6ae",
              padding: "14px 14px",
              fontSize: "14px",
            }}
            variant="contained"
            // endIcon={<NavigateNextIcon />}
            onClick={() => testRestartDialoq()}
          >
            Testi yenidən başla
          </Button>
          }
        </div>
      </div>
    </div>
  );
};
export default NavBlock;
