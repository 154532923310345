import React from 'react';
import  './NotFound.css';

const NotFound = () => (
  <div className="error-main">
  <h1>Oops!</h1>
  <div className="error-heading">404</div>
</div>
);

export default NotFound;